import React from "react";
import { useState } from "react";
import { Wrapper, VirtualMobile, ButtonWrapper, ListWrapper, Separator, FormFieldWrapper,LandingSlider,PhotosWrapper, CarrouselWrapper2, ImageWrapper, ArtistBioWrapper,ArtistImage, ArtistBio,Atelier,GifTile,CacheCache,Quote,QuoteWrapper, Slide, SlideImage,ImageArtistWrapper, ArtistBioText} from "./Epode.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import addToMailchimp from 'gatsby-plugin-mailchimp'
import ANA from '../../../../res/monso/portrait.jpeg'
import MATHIAS from '../../../../res/bensimon/portrait.jpg'
import AnaOeuvre1 from '../../../../res/monso/ana-oeuvre-1.png'
import AnaOeuvre2 from '../../../../res/monso/ana-oeuvre-2.png'
import AnaOeuvre3 from '../../../../res/monso/ana-oeuvre-3.png'
import AnaOeuvre4 from '../../../../res/monso/ana-oeuvre-4.png'
import AnaOeuvre5 from '../../../../res/monso/ana-oeuvre-5.png'
import AnaOeuvre6 from '../../../../res/monso/ana-oeuvre-6.png'
import AnaOeuvre7 from '../../../../res/monso/ana-oeuvre-7.png'
import AnaAtelier1 from '../../../../res/monso/atelier/atelier-1.jpeg'
import AnaAtelier2 from '../../../../res/monso/atelier/atelier-2.jpeg'
import AnaAtelier3 from '../../../../res/monso/atelier/atelier-3.jpeg'
import MathiasOeuvre1 from '../../../../res/bensimon/mathias-oeuvre-1.png'
import MathiasOeuvre2 from '../../../../res/bensimon/mathias-oeuvre-2.png'
import MathiasOeuvre3 from '../../../../res/bensimon/mathias-oeuvre-3.png'
import MathiasOeuvre4 from '../../../../res/bensimon/mathias-oeuvre-4.png'
import MathiasOeuvre5 from '../../../../res/bensimon/mathias-oeuvre-5.png'
import MathiasOeuvre6 from '../../../../res/bensimon/mathias-oeuvre-6.png'
import MathiasOeuvre7 from '../../../../res/bensimon/mathias-oeuvre-7.png'
import MathiasAtelier1 from '../../../../res/bensimon/atelier/atelier-1.jpg'
import MathiasAtelier2 from '../../../../res/bensimon/atelier/atelier-2.jpg'
import MathiasAtelier3 from '../../../../res/bensimon/atelier/atelier-3.jpg'
import EPODE from "../../../../res/epode/epode-slide.jpg"
import EPODETILE from "../../../../res/epode/epode.jpg"
import SITUATION1 from "../../../../res/epode/situations/situation-1.jpg"
import SITUATION2 from "../../../../res/epode/situations/situation-2.jpg"
import SITUATION3 from "../../../../res/epode/situations/situation-3.jpg"
import SITUATION4 from "../../../../res/epode/situations/situation-4.jpg"
import SITUATION5 from "../../../../res/epode/situations/situation-5.jpg"
import CP from "../../../../res/epode/cp_epode.pdf"
import Title from "../../../../components/Title";
import CatalogueAna from '../../../../res/monso/catalogue_ana.pdf'
import CatalogueMathias from '../../../../res/bensimon/catalogue_mathias.pdf'



// import FeedInstagram from "../../../components/FeedInstagram";


const Landing = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const responsiveArtist = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };


  const oeuvresANA = 
    {
      photos: [
        { src: AnaOeuvre1, artist: "Ana Monsó", title: "No es para tanto, 2023", dimension: "200 x 200 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre2, artist: "Ana Monsó", title: "Cambio los planes, 2023", dimension: "200 x 200 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre3, artist: "Ana Monsó", title: "Entre castillos y palmeras 2, 2022", dimension: "180 x 155 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre4, artist: "Ana Monsó", title: "Esta bien asi, 2023", dimension: "146 x 114 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre5, artist: "Ana Monsó", title: "Todo es un instante, 2023", dimension: "146 x 114 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre6, artist: "Ana Monsó", title: "This is what it looks like, 2023", dimension: "116 x 89 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"},
        { src: AnaOeuvre7, artist: "Ana Monsó", title: "Como eran las cosas, 2023", dimension: "130 x 162 cm", copyright: "", style: "Acrylic, pastels, pencils, markers and spray can on cotton canvas"}
      ],
  };

  const oeuvresMATHIAS = 
  {
    photos: [
      { src: MathiasOeuvre1, artist: "Mathias Bensimon", title: "Vibration n°3, 2023", dimension: "200 x 140 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre2, artist: "Mathias Bensimon", title: "Untitled, 2022", dimension: "100 x 81 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre3, artist: "Mathias Bensimon", title: "Untitled, 2022", dimension: "130 x 97 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre4, artist: "Mathias Bensimon", title: "Untitled, 2022", dimension: "116 x 89 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre5, artist: "Mathias Bensimon", title: "Untitled, 2022", dimension: "100 x 65 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre6, artist: "Mathias Bensimon", title: "Untitled, 2022", dimension: "116 x 89 cm", copyright: "", style: "Oil on cotton canvas"},
      { src: MathiasOeuvre7, artist: "Mathias Bensimon", title: "Le ballet cosmique, 2023", dimension: "200 x 140cm", copyright: "", style: "Oil on cotton canvas"}
    ],
};

  // const [email, setEmail] = useState("");

  const [disp, setDisp] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);
  const [fields, setFields] = useState({
    FNAME: '',
    LNAME: ''
  })

  const swapDisp=()=> {
    setDisp((disp) => !disp);
    console.log("disp === " + disp);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, fields);
    setResult(result);
    console.log("result=>",result.result);
    // but you can do whatever you want
  }

  const handleEmail = async (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log("email=>".email);

  }
  const handleName = async (e) => {
    e.preventDefault();
    setFields({
      FNAME: e.target.value
    });
    console.log("fields=>".fields);
  }
  return (
    <div >
        <div className={GifTile}>
        <img src={EPODETILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={EPODE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>EPODE</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Ana Monsó, Mathias Bensimon</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>DUO SHOW</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            07th APRIL -<br/>13th MAY 2023
            </p>
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/fr/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a href={CP} style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>PRESS RELEASE</p></a>
          <a href="/fr/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/en/visite-virtuelle-epode" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VIRTUAL VISIT</p></a>
        </div>
      </div>
      <div className={VirtualMobile}>
        <a href="/fr/visite-virtuelle-epode" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
      </div>


        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        Epode becomes a fragmentary response, where the exhibition space becomes a tangibilia, a sensitive object, the prism through which the substantive light of the work moves. This is where the poetics of Ana Monsó and Mathias Bensimon unfold. They question the variations of the work, and the relationship it has with space and light; consubstantial entities of the art object. Within this exhibition, the paintings presented by Ana Monsó force us to question the message of the work, to look for the singular, the nuance; like the solitary gaze that sweeps through urban life, Ana forces the viewer to take time, to stop on the hidden signs of a memorial presence; symbolic where the poetic vision arises: letters, words, which reveal the metaphor of the memory, barely visible, a that-was.
          </p>
        <br />
        </div>
        <div className={QuoteWrapper}>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p style={{fontWieight: "bold"}}>What does the title EPODE say about your work and your way of painting? What does it mean to you?</p>
            <br />
            <p className={Quote}>
            In my works there is a coexistence of two separate worlds, made visible in one: the rational world and the poetic world. What we see are nostalgic abstract reinterpretations of my own memories, reinterpretations that are resolved in two stages. The first is the rational stage, where I reinterpret my memories by freely drawing what the moment I am reviving suggests to me. The second is the poetic stage, in which the symbols created during the "rational" stage are covered with a layer of paint. By adding the paint freely, I don't know what will or will not be visible once it dries. This is a metaphor for what remains visible of that memory now in me, as some elements of the memory have been erased, as in the paint. The two stages, or in other words, the two worlds (poetic and rational) coexist and work together to create a final piece, a song, that leads to a new world; just as in an Epode where two unequal verses form the final lyrical couplet. Ana Monsó

            </p>
          </div>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p style={{fontWieight: "bold"}}>What does the title EPODE say about your work and your way of painting? What does it mean to you?</p>
          <br />
            <p className={Quote}>
            To evoke what the word epode brought to life in me, I would quote Horace: "But I, for whom life has no charm but you
living, if not unbearable?
You tell me to continue in my leisure.
Will I do it? It is sweet only with you."
In order to provoke an existential reflection, I would like to address the question: By what are we animated?
The enigma of creation has always been sublimated by an existential dichotomy, a subtle balance between evanescence and timelessness. It is indeed that our perception of the world is based on three constituent elements, matter, energy and knowledge (information). Thus, an artistic creation is itself tinged with these principles, by incorporating matter, from our daily life, energy, embodied by gesture, movement and action, as well as affective or sensory intelligence, the emotional tone that emanates from the work and the experience it provokes.
Being similar to a fundamental necessity for the human being, it is however deprived of any practical utility, carrying in itself this paradox which makes of it at the same time a spiritual manifestation (of the spirit) and physical (in matter) by inducing a sensitive experience. Mathias Bensimon
              </p>
          </div>
        </div>
        
        <div className={Wrapper}>
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}} >
        An individuation that we find through the paintings of Mathias Bensimon, for whom the light has a materiality of its own, a consistency. He makes of it the architect, the demiurge, which, by the movement, gives life to the various colors which different shades that dance under the eyes of the viewer. This multitude of individual characters gives his works the free interpretation of their poetry, what he calls "space"; an intimate relationship between the subjectivity of the subject looking and the object looked at. Two singular artists are presented here by the Ghost Galerie. Two artists who, with regard to the urban art of graffiti and post-graffiti, espouse an approach, if not similar, at least concomitant with a need to abandon the formal and conceptual structure, and this, in an increased desire to awaken the subjective sensitivity of the viewer. Léon Vuillecard
          </p>
        <br />
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={ANA}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Ana Monsó</p>
            <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Born in 1998 in Barcelona, Spain</p>
            <p className={ArtistBioText}>
            Studying at Cambridge (Art and Design) and then in London (Fashion Design at the University of the Arts), Ana Monsó defines herself as multidisciplinary artist. Her artistic practice conveys a spontaneous spirit inspired by her childhood memories. "When I grow up, I want to be a kid again". She is currently one of the resident artists of the contemporary art program Piramidón in Barcelona, along with twenty other artists, and has just been selected to join the Royal Academy of Art in London in 2023.
              </p>
              <br />
            <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Download catalog</a>
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Selection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresANA.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>
                <img
                  className={ImageWrapper}
                  style={{objectFit: "contain"}}
                  // key={image.name}
                  // alt={image.name}
                  src={image.src}
                />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
          <div className={Atelier}>
          <Carousel 
              swipeable={false}
              draggable={false}
              slidesToSlide={1}
              responsive={responsive}
              autoPlay={false}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              partialVisible={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              >
            <div style={{height: "75vh",display: "flex",marginTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center",}}>
                <img src={AnaAtelier1} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src={AnaAtelier2} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
                </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={AnaAtelier3} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
          </Carousel>
          </div>

        </div>
      </div>
      </div>
      <div className={Wrapper}>
        <div className={ArtistBioWrapper} style={{paddingTop: "100px"}}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={MATHIAS}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Mathias Bensimon</p>
            <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Born in 1996 in Paris, France</p>
            <p className={ArtistBioText}>
            Graduated from the Beaux-Arts de Paris in 2022, Mathias Bensimon is a French artist, visual artist, performer and dancer. He received a multiple training within the academy: Ann Veronica Janssens (light and transparency), Wernher Bouwens (color and installation), Emmanuelle Huynh (movement) allowing him to participate in various artistic projects in France and abroad: Museu Arqueologico do Carmo, Museum of Fine arts, Ho Chi Minh City, the Temple of Hesadera, Tokyo Musashino museum, the Grand Palais, chez Paulin in Paris alongside Anish Kapoor; to participate in Prizes (Dior Prize, Château de la Colle Noire); artistic events (nuit Blanche in Paris in 2022). Or to realize monumental frescoes (Cogedim, Jean-Michel Wilmotte).</p>
          <br />
          <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Download catalog</a>
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Selection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresMATHIAS.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
        <div style={{paddingTop: "50px"}}>
          <div className={Atelier}>

          <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={MathiasAtelier1} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
        </div>
      </div>
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={MathiasAtelier2} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
          </div>
      </div>
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={MathiasAtelier3} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
        </div>
      </div>
    </Carousel>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className={QuoteWrapper}>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p style={{fontWieight: "bold"}}>WHEN I GROW UP, I WANT TO BE A CHILD AGAIN</p>
            <br />
            <p className={Quote}>I center my work on the self-reflection of sacred moments in my life. Drawing from my childhood memories, I create freely, allowing the final work to be a fusion of my inspirations. My work is an ode to latent childlike innocence. I try to reinterpret the world without rules and boundaries, as a child would do before social judgment and established norms were imposed. Ana Monsó</p>
          </div>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p  style={{fontWieight: "bold"}}>STATEMENT</p>
            <br />
            <p className={Quote}>In the field of my practice, light constitutes the heart of my work. Light as a medium and substance, but also light as a revealing intelligence. As a testimony of the different manifestations of reality, light can appear to us under a multitude of forms and reflections. It raises the question of the visible and the invisible. How to make visible the invisible? How to touch the immaterial source while passing by the matter? Mathias Bensimon</p>
          </div>
        </div>

         
      <div className={Wrapper}>
      <div className={Atelier}>
          <Carousel 
              swipeable={false}
              draggable={false}
              slidesToSlide={1}
              responsive={responsive}
              autoPlay={false}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              partialVisible={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              >
            <div style={{height: "75vh",display: "flex",marginTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center",}}>
                <img src={SITUATION1} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src={SITUATION2} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
                </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION3} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION4} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION5} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
          </Carousel>
          </div>
      </div>
      <br />
          <br />
          <br />
      </div> 
  );
};

export default Landing;